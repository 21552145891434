@import "variables";

/* Service débarras */
.service_debarras {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, rgba(18, 27, 33, 0.55) 100%),
        url("../../assets/images/main-bg.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll !important;

    overflow: hidden;

    height: 890px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1920px) {
    .service_debarras {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, rgba(18, 27, 33, 0.55) 100%),
            url("../../assets/images/main-bg-medium.jpg") no-repeat center center fixed;
    }
}

.service_debarras__text {
    width: 650px;
    max-width: 100%;

    margin-bottom: 85px;

    color: white;

    h1 {
        font-size: 70px;
        line-height: 70px;
        letter-spacing: -1px;
        text-align: center;
    }

    p {
        font-size: 21px;
        line-height: 34px;
        text-align: center;
    }
}

.service_debarras__actions {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 100%;

    a {
        text-decoration: none;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        line-height: 28px;
        font-size: 24px;
        font-weight: bold;

        max-width: 100%;
        width: 400px;
        height: 90px;

        transition: font-size 0.25s ease-in-out;
    }

    .service_debarras__actions_main {
        background-color: $primary;
        color: white;
    }

    .service_debarras__actions_secondary {
        background-color: white;
        color: $primary;
    }
}

@media screen and (min-width: $maxWidthMobile) {
    .service_debarras__actions {
        a:hover {
            font-size: 28px;
        }
    }
}

@media screen and (max-width: $maxWidthMobile) {
    .service_debarras__actions {
        a {
            font-size: 17px;
        }
    }
}
